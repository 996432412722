import { VariantProps } from 'class-variance-authority'
import { forwardRef } from 'react'
import { Button, buttonVariants } from '~/components/ui/button'
import { BubbleSpinners } from '~/components/ui/bubble-spinner'
export interface SubmissionButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  isSubmitting: boolean
}

const SubmissionButton = forwardRef<HTMLButtonElement, SubmissionButtonProps>(
  ({ isSubmitting, children, ...props }, ref) => {
    return (
      <Button type='submit' {...props} ref={ref}>
        {isSubmitting ? (
          <BubbleSpinners
            size='24px'
            color='#ffffff'
          />
        ) : (
          children
        )}
      </Button>
    )
  }
)

export default SubmissionButton
