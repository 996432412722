export const BubbleSpinners = ({ size = '24px', color = '#333' }) => {
  return (
    <div
      className='spinner flex justify-center'
      style={
        {
          '--spinner-size': size,
          '--spinner-color': color,
        } as React.CSSProperties
      }
    >
      <div className='bounce1 inline-block rounded-full'></div>
      <div className='bounce2 mx-2 inline-block rounded-full'></div>
      <div className='bounce3 inline-block rounded-full'></div>
    </div>
  )
}
